



















































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UploadError from "@/components/UploadError.vue";
import { setPageTitle } from "@/utils/meta";
import Card from "@/components/Card.vue";
import formatter from "@/mixins/formatter";
import importVersion from "@/pages/stock/Import.vue"
import { ExportStockVersions } from "@/services/xlsx.ts";
@Component({
    components: { Card, UploadError, importVersion },
    mixins: [formatter]
})
export default class StockSetting extends Vue {

    @Prop() indexId!: number;
    @Prop() stockId!: number;
    @Prop() stockName!: string;
    error = null;
    uploadingData = false;
    doneUpload = false;
    companyId = 0;
    countryId = null;
    seuil = null;
    startVariation = null; //new Date();
    menu = false;
    companies = [];
    selectedCompanies = [];
    countries = [];
    loading = true;
    exist = false;
    update = false;
    importDialog = false;
    model = null;
    isPanelOpen = [0, 1]
    indexVersions = []
    selectedIndexVersion = []
    selectedVersionId = null
    tabl = 0;
    tabl2 = 0;
    msiVersions = []

    @Watch('selectedIndexVersion')
    changeComposition() {
        const selectedItem = this.indexVersions.find(item => item.id === this.selectedIndexVersion);
        // this.companies = selectedItem?.stockExchangeComposition
        // console.log(selectedItem?.stockExchangeComposition)
    }
    created() {
        setPageTitle("Stock Setting ");

        this.loadMsiComposition();
        // this.loadCompanies();
        this.loadIndexComposition()
        this.$api.get("country?per-page=0").then(j => (this.countries = j.data));
    }

    // loadCompanies() {
    //     return this.$api.get(`company/list?stock=${this.stockId}`).then(res => {
    //         this.companies = res.data;
    //         this.loading = false;
    //     });
    // }

    configImported() {
        this.importDialog = false;
        // this.$refs.grid.getModels();
        this.loadIndexComposition();
    }

    loadMsiComposition() {
        return this.$api.get(`stock-exchange/list-composition?stock=${this.stockId}`).then(res => {
            this.msiVersions = res.data.msiVersion
            this.seuil = res.data.seuil;
            const lastVersionIndex = this.msiVersions.length - 1;
            this.tabl2 = lastVersionIndex !== -1 ? lastVersionIndex : 0;
            this.countryId = res.data.country_id;
            if (this.selectedCompanies.length > 0) {
                this.exist = true;
            }
            //this.loadCompanies();
        });
    }
    loadIndexComposition() {
        return this.$api.get(`stock-exchange/${this.stockId}?expand=stockExchangeVersions.stockExchangeComposition.company`).then(res => {
            this.indexVersions = res.data?.stockExchangeVersions || []

            const lastVersionIndex = this.indexVersions.length - 1;
            this.tabl = lastVersionIndex !== -1 ? lastVersionIndex : 0;
        });
    }
    xls() {
        ExportStockVersions("StockVersion", this.indexVersions, "StockVersions.xlsx");
    }
    addCompany() {

        const selectedCompany = this.companies.find(company => company.id === this.companyId);
        // Check if the company with the same ID already exists in selectedCompanies
        const exists = this.selectedCompanies.some(company => company.id === this.companyId);
        this.doneUpload = false;
        // If the company doesn't exist in selectedCompanies, add it
        if (selectedCompany && !exists) {
            if (this.exist) {
                this.uploadingData = true;

                this.$api.post(`stock-exchange/add-company?index=${this.indexId}&company=${this.companyId}`).
                    then(response => {
                        this.doneUpload = true;
                    }).catch((e) => {
                        this.error = e.response.data;
                    }).finally(() => {
                        this.uploadingData = false;
                        //this.doneUpload = false;
                        //this.loadComposition();
                    });
            }
            this.selectedCompanies.push({
                id: selectedCompany.id,
                name: selectedCompany.name,
                symbol: selectedCompany.symbol
            });
        } else {
            const msg = 'company exist deja';
        }
        this.companyId = null;
        console.log('jannt', this.selectedCompanies);
    }
    deleteCompany(id) {
        this.doneUpload = false;
        this.$root.$confirm
            .open(
                "Delete company",
                "Are you sure you want to delete this company ?",
                {}
            )
            .then(confirm => {
                if (confirm) {
                    if (this.exist) {
                        this.uploadingData = true;
                        this.$api.post(`stock-exchange/remove-company?index=${this.indexId}&company=${id}`).
                            then(response => {
                                this.doneUpload = true;
                            }).catch((e) => {
                                this.error = e.response.data;
                            }).finally(() => {
                                this.uploadingData = false;
                                // this.loadComposition();
                                //this.doneUpload = false;
                            });
                    }
                    this.selectedCompanies = this.selectedCompanies.filter(company => company.id !== id);
                    if (this.selectedCompanies.length == 0) {
                        this.exist = false;
                    }
                }
            });
    }
    updateSeuil(seuil) {
        this.seuil = seuil;
    }
    uploadData() {
        this.uploadingData = true;
        this.update = false;

        const symbols = this.selectedCompanies.map(company => company.symbol);
        if (this.exist) {
            this.update = true;
        }
        this.error = null;

        this.$api.post(`stock-exchange/integrate-index?stock=${this.stockId}&seuil=${this.seuil}`, {
        }).then(response => {
            this.uploadingData = false;
            this.doneUpload = true;
            this.loadMsiComposition();
        }).catch((e) => {
            this.error = e.response.data;
            this.uploadingData = false;
        }).finally(() => {
            // this.loadComposition();
            this.doneUpload = false;
            // window.location.reload();
        });
    }

    importFScompanies() {
        this.uploadingData = true;

        const symbols = this.selectedCompanies.map(company => company.symbol);
        this.error = null;

        this.$api.post(`company/fmp-financial?nbrYear=1&externe=true`, {
            symbol: symbols,
        }).catch((e) => {
            this.error = e.response.data;
        }).finally(() => {
            this.uploadingData = false;
            this.doneUpload = true;
        });
    }
    importComposition() {

    }
    viewCompany(id) {
        const routePath = `/company/${id}`;
        // Use window.open to open the route in a new tab
        window.open(routePath, '_blank');
        //this.$router.push(`/company/${id}`);
    }

    viewIndex() {
        const routePath = `/stock/${this.indexId}`;
        window.open(routePath, '_blank');
    }
}
