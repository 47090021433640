
































import { Component, Vue, Prop } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import UploadError from "@/components/UploadError";

@Component({
  components: { UploadError },
  mixins: [formatter],
})
export default class VersionImport extends Vue {
  @Prop() stockId!: number;
  versionFile = null;
  countryId = null;
  sectorId = null;
  uploadPercentage = 0;
  uploadingConfig = false;
  countries = [];
  sectors = [];
  typeConfig = '20000'; //[{'key':'20000','value':'20000'},{'key':'smart','value':'Smart MSI'}];

  error = null;

  loading = true;

  created() {
    this.$api.get(`country?expand=region&per-page=0`).then(res => {
      this.countries = res.data;
      this.$api.get(`sector?per-page=0`).then(res => {
        this.sectors = res.data;
        this.loading = false;
      });
    });
  }

  get canUpload() {
    return (
      this.versionFile !== null &&
      !this.uploadingConfig &&
      this.stockId
    );
  }

  uploadVersions() {
    const state = this.$store.state;

    this.uploadingConfig = true;
    const formData = new FormData();
    const versionUpload = {
      stockId: this.stockId,
      status: "uploading",
      percent: 0,
    };
    console.log(versionUpload)
    // return
    state.versionUploads.push(versionUpload);
    formData.append("file", this.versionFile);
    formData.append("stock_id", this.stockId);
    // formData.append("sector_id", this.sectorId);
    // formData.append("type", this.typeConfig);

    this.error = null;
    this.$emit("started");
    this.$api
      .post("stock-exchange/import-versions", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = state.versionUploads.percent = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          if (this.uploadPercentage === 100) state.versionUploads.status = "parsing";
        }.bind(this),
      })
      .then(() => {
        state.versionUploads.status = "done";
        this.$emit("done");

        setTimeout(() => {
          state.versionUploads = state.versionUploads.filter(
            cu => cu != state.versionUploads
          );
        }, 1000);
      })
      .catch(e => {
        console.log("ERROR", e);
        this.error = e.response.data;
        state.versionUploads.status = "failed";
        this.uploadingConfig = false;
      })
      .finally(() => {
        this.uploadingConfig = false;
      });
  }
}
