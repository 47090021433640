


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UploadError from "@/components/UploadError.vue";
import { setPageTitle } from "@/utils/meta";
import Card from "@/components/Card.vue";
import formatter from "@/mixins/formatter";
import { PortfolioComposition } from "@/smartmsi";
import DatetimePicker from 'vuetify-datetime-picker';
import VersionsPopup from '@/pages/portefeuille/VersionsPopup.vue'

// import 'vuetify-datetime-picker/src/stylus/main.styl';
Vue.use(DatetimePicker)
@Component({
    components: { Card, UploadError, VersionsPopup },
    mixins: [formatter],
})
export default class PortefeuilleSetting extends Vue {
    @Prop() portfolioId!: number;
    @Prop() portfolioName!: string;
    @Prop() portfolioDevise!: string;
    // composition: Partial<PortfolioComposition>[] = [];
    error = null;
    errorMessages: string[] = [];
    rules = {
        rangeScore: [
            (value: number | null) => value === null || value >= 1 || 'Value must be at least 1',
            (value: number | null) => value === null || value <= 200 || 'Value must be at most 200'
        ],

    };
    filterDebounce!: NodeJS.Timeout;
    composition: any = []
    composition2: any = []
    uploadingData = false;
    doneUpload = false;
    edit = false;
    newAction = 1;
    companyId = 0;
    countryId = null;
    countryFilterId = [];
    sectorId = [];
    slider = 45
    indexId = null;
    scoreRange = [0, 200]
    performanceRange = [0, 100]
    solidityRange = [0, 100]
    liquidityRange = [0, 10]
    assetRange = [0, 10]
    liabilityRange = [0, 10]
    riskRange = [0, 10]
    commercialRange = [0, 10]
    economicRange = [0, 10]
    financialRange = [0, 10]
    operatingRange = [0, 10]
    sortedPortfolioVersions = []
    chiffreAffaireMin = null
    chiffreAffaireMax = null
    resNetMin = null
    resNetMax = null
    totalActifMin = null
    totalActifMax = null
    totalPassifMin = null
    totalPassifMax = null
    capitauxMin = null
    capitauxMax = null
    currentDate = "";
    yesterdayDate = "";
    companies = [];
    selectedCompanies: any = [];
    countries = [];
    sectors = [];
    indices = [];
    loadingData = false;
    exist = true;
    openDialogVariationDate = false
    update = false;
    scoreMoy = 0;
    soliditeMoy = 0;
    performanceMoy = 0;
    total = 0;
    valo = "";
    startVariation = null;
    menu = false;
    menu2 = false;
    fmp = true;
    operator = null
    totalItems = 0    // Total number of items (from API response)
    totalPages = 0     // Total number of pages (from API response)
    perPage = 50        // Items per page (default value)
    currentPage = 1     // Current page (default value)
    loadingTable = true
    selectedDate = null
    selectedTime = null
    formatedStartVariation = null
    isVersionPopup = false
    lastVersion = null
    portfolio = null
    disableFilters = true
    portfolioActions = []
    filters = {
        liquidity: {
            value: null,
            operator: '',
        },
        actifs: {
            value: null,
            operator: '',
        },
        passifs: {
            value: null,
            operator: '',
        },
        risques: {
            value: null,
            operator: '',
        },
        rentabilite: {
            value: null,
            operator: '',
        },
        rentabilite_economique: {
            value: null,
            operator: '',
        },
        rentabilite_financiere: {
            value: null,
            operator: '',
        },
        rentabilite_exploitation: {
            value: null,
            operator: '',
        },
    }
    tabl = 0;
    operators = ['<', '<=', '>', '>='];
    snackbar = false;
    timeout = 5000
    text = ''
    isPanelOpen = [0, 1, 2, 3]
    isPanelFiltersOpen = [0, 1]
    endpoint = "company?expand=country,legalTypology,companyActivities.activity.sector,stockExchangeComposition.stockExchangeVersion.stockExchange&page=${page}&per-page=50&sort=-score&filter[enabled][in][]=1"
    get headers() {
        return [
            // { text: "", value: "_actions" },

            { text: this.$i18n.t("models.company.name"), value: "name" },
            {
                text: this.$i18n.t("ISIN"),
                value: "profil_fmp.isin"
            },
            {
                text: this.$i18n.t("models.company.country"),
                value: "country.name",
                width: 1,
            },

            {
                text: this.$i18n.t("score"),
                filterable: false,
                value: "score",
            },
            {
                text: this.$i18n.t("performance"),  // New "Close" column
                filterable: false,
                value: "performance_score",

            },
            {
                text: this.$i18n.t("Solidité"),  // New "Nbr Action" column
                filterable: false,
                value: "solidity_score",
            },
            {
                text: this.$i18n.t("origin"),
                value: "origin",
            },
        ];
    }
    @Watch("portfolio")
    onChangeportfolio() {
        this.sortedPortfolioVersions = (this.portfolio.portfolioVersions || []).sort((a, b) => {
            // Ensure date_version is a valid date string
            const dateA = new Date(a.date_version).getTime();
            const dateB = new Date(b.date_version).getTime();

            // Handle potential invalid date scenarios
            return isNaN(dateA) || isNaN(dateB) ? 0 : dateA - dateB;
        });
    }


    @Watch("countryFilterId")
    onValueChanged() {
        this.loadIndices(this.countryFilterId);
    }
    @Watch("selected")
    oncheckChanged() {
        // console.log("seleced", this.selected)
    }

    @Watch("startVariation")
    formated() {
        const date = new Date(this.startVariation);

        // Adjust for the local timezone offset
        const timezoneOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds

        // Adjust the time by subtracting the offset to ensure local time
        const localDate = new Date(date.getTime() - timezoneOffset);

        // Format the date as yyyy-MM-ddTHH:mm
        this.formatedStartVariation = localDate.toISOString().slice(0, 16);
    }
    @Watch("composition")
    logCompo() {
        // console.log(this.composition)
    }
    chiffreAffaireMin = null
    chiffreAffaireMax = null
    resNetMin = null
    resNetMax = null
    totalActifMin = null
    totalActifMax = null
    totalPassifMin = null
    totalPassifMax = null
    capitauxMin = null
    capitauxMax = null

    @Watch("countryFilterId")
    @Watch("sectorId")
    actionCountryFilter() {
        this.disableFiltersChange()
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);

    }
    @Watch("scoreRange")
    @Watch("performanceRange")
    @Watch("solidityRange")
    @Watch("indexId")
    onFilterChanged() {
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);
    }

    // Watchers for filter properties
    @Watch('liquidityRange')
    @Watch('assetRange')
    @Watch('liabilityRange')
    @Watch('riskRange')
    @Watch('commercialRange')
    @Watch('economicRange')
    @Watch('financialRange')
    @Watch('operatingRange')
    onLiquidityFilterChanged() {
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);
    }

    @Watch("resNetMin")
    @Watch("resNetMax")
    @Watch("totalActifMin")
    @Watch("totalActifMax")
    @Watch("totalPassifMin")
    @Watch("totalPassifMax")
    @Watch("capitauxMin")
    @Watch("capitauxMax")
    onFilterChiffreChanged() {
        if (this.chiffreAffaireMin !== null && this.chiffreAffaireMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterResNetChanged() {
        if (this.resNetMin !== null && this.resNetMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterTotalActiffChanged() {
        if (this.totalActifMin !== null && this.totalActifMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterTotalPassifChanged() {
        if (this.totalPassifMin !== null && this.totalPassifMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterCapitauxChanged() {
        if (this.capitauxMin !== null && this.capitauxMax !== null) {
            this.loadCompanies()
        }
    }
    created() {
        setPageTitle("Portfolio Setting ");

        this.loadCompanies();
        this.loadCountries();
        this.loadSectors();
        this.loadIndices();
        // this.loadPortfolio();
        this.loadPortfolioInfo();
        // this.$api.get("country?per-page=0").then(j => (this.countries = j.data));
        const today = new Date();
        // Format current date as 'YYYY-MM-DD'
        this.currentDate = this.formatDate(today);

        // Get yesterday's date
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 2); // Subtract 1 day
        // Format yesterday's date as 'YYYY-MM-DD'
        this.yesterdayDate = this.formatDate(yesterday);
        this.loadActions()

    }
    buildFilterString(): string {
        let filterString = '';

        if (this.countryFilterId) {
            filterString += `&filter[country.id]=${this.countryFilterId}`;
        }
        // Handle multiple selected country
        if (this.countryFilterId && this.countryFilterId.length > 0) {
            this.countryFilterId.forEach((id: number) => {
                filterString += `&filter[country.id][in][]=${id}`;
            });
        }
        // Handle multiple selected sectors
        if (this.sectorId && this.sectorId.length > 0) {
            this.sectorId.forEach((id: number) => {
                filterString += `&filter[sector.id][in][]=${id}`;
            });
        }

        if (this.indexId) {
            filterString += `&filter[stock_exchange.id][in][]=${this.indexId}`;
        }

        if (this.scoreRange && this.scoreRange.length === 2 && !(this.scoreRange[0] === 0 && this.scoreRange[1] === 200)) {
            filterString += `&filter[score][like]=${this.scoreRange.join(',')}`;
        }

        if (this.performanceRange && this.performanceRange.length === 2 && !(this.performanceRange[0] === 0 && this.performanceRange[1] === 100)) {
            filterString += `&filter[company.performance_score][like]=${this.performanceRange.join(',')}`;
        }

        if (this.solidityRange && this.solidityRange.length === 2 && !(this.solidityRange[0] === 0 && this.solidityRange[1] === 100)) {
            filterString += `&filter[company.solidity_score][like]=${this.solidityRange.join(',')}`;
        }
        if (this.liquidityRange && this.liquidityRange.length === 2 && !(this.liquidityRange[0] === 0 && this.liquidityRange[1] === 10)) {
            filterString += `&filter[liquidity][like]=${this.liquidityRange.join(',')}`;
        }
        if (this.assetRange && this.assetRange.length === 2 && !(this.assetRange[0] === 0 && this.assetRange[1] === 10)) {
            filterString += `&filter[actifs][like]=${this.assetRange.join(',')}`;
        }
        if (this.liabilityRange && this.liabilityRange.length === 2 && !(this.liabilityRange[0] === 0 && this.liabilityRange[1] === 10)) {
            filterString += `&filter[passifs][like]=${this.liabilityRange.join(',')}`;
        }
        if (this.riskRange && this.riskRange.length === 2 && !(this.riskRange[0] === 0 && this.riskRange[1] === 10)) {
            filterString += `&filter[risques][like]=${this.riskRange.join(',')}`;
        }
        if (this.commercialRange && this.commercialRange.length === 2 && !(this.commercialRange[0] === 0 && this.commercialRange[1] === 10)) {
            filterString += `&filter[rentabilite][like]=${this.commercialRange.join(',')}`;
        }
        if (this.economicRange && this.economicRange.length === 2 && !(this.economicRange[0] === 0 && this.economicRange[1] === 10)) {
            filterString += `&filter[rentabilite_economique][like]=${this.economicRange.join(',')}`;
        }
        if (this.financialRange && this.financialRange.length === 2 && !(this.financialRange[0] === 0 && this.financialRange[1] === 10)) {
            filterString += `&filter[rentabilite_financiere][like]=${this.financialRange.join(',')}`;
        }
        if (this.operatingRange && this.operatingRange.length === 2 && !(this.operatingRange[0] === 0 && this.operatingRange[1] === 10)) {
            filterString += `&filter[rentabilite_exploitation][like]=${this.operatingRange.join(',')}`;
        }
        if (this.chiffreAffaireMin !== null && this.chiffreAffaireMax !== null) {
            filterString += `&filter[chiffre_affaire][like]=${this.chiffreAffaireMin},${this.chiffreAffaireMax}`;
        }
        if (this.resNetMin !== null && this.resNetMax !== null) {
            filterString += `&filter[res_net][like]=${this.resNetMin},${this.resNetMax}`;
        }
        if (this.totalActifMin !== null && this.totalActifMax !== null) {
            filterString += `&filter[total_actif][like]=${this.totalActifMin},${this.totalActifMax}`;
        }
        if (this.totalPassifMin !== null && this.totalPassifMax !== null) {
            filterString += `&filter[total_passif][like]=${this.totalPassifMin},${this.totalPassifMax}`;
        }
        if (this.capitauxMin !== null && this.capitauxMax !== null) {
            filterString += `&filter[capitaux][like]=${this.capitauxMin},${this.capitauxMax}`;
        }
        return filterString;
    }
    truncatedName(name) {
        if (name.length > 50) {
            return name.slice(0, 50) + "...";
        }
        return name;
    }

    loadCompanies() {
        const filterString = this.buildFilterString();

        this.endpoint = `company?expand=country,legalTypology,companyActivities.activity.sector,stockExchangeComposition.stockExchangeVersion.stockExchange&per-page=50&filter[enabled][in][]=1${filterString}`
    }
    // Debounce function to limit the rate of execution
    debounce(func: Function, delay: number) {
        let timeout: number | undefined;
        return (...args: any[]) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    }

    // Utility function to update composition based on selected items
    updateComposition(selectedItems: any[]) {
        const selectedIds = new Set(selectedItems.map(item => item.id));

        // Remove items that are no longer selected
        this.composition = this.composition.filter(comp => selectedIds.has(comp.company_id));

        // Add new selected items that are not already in composition
        const existingIds = new Set(this.composition.map(comp => comp.company_id));
        selectedItems.forEach(item => {
            if (!existingIds.has(item.id)) {
                this.loadComposition(item); // Adjust if needed
            }
        });

        // Debug output
        // console.log("Selected Items:", selectedItems);
        // console.log("Updated Composition:", this.composition);
    }
    removeComp(index) {
        const item = this.composition[index]
        this.composition.splice(index, 1)
        // console.log("this.composition[index].company_id", item.company_id)

        this.selectedCompanies = this.selectedCompanies.filter(
            company => company.id !== item.company_id
        );
        // console.log("after remove compo", this.selectedCompanies)
    }
    onSelectionChange = this.debounce(({ selectedItems, allItems }: { selectedItems: any[]; allItems: any[] }) => {
        this.companies = allItems; // Store the updated companies list
        // console.log("this.companies", this.companies);

        // Ensure `selectedItems` is an array
        if (!Array.isArray(selectedItems)) return;

        // Update composition based on selected items
        this.updateComposition(selectedItems);
    }, 1000); // Adjust debounce delay as needed
    loadCountries() {
        return this.$api.get(`country`).then(res => {
            this.countries = res.data;
            // console.log("countrys",res.data)
        });
    }
    loadActions() {
        return this.$api.get(`portfolio/get-actions?portfolio=${this.portfolioId}`).then(res => {
            this.portfolioActions = res.data;
            // console.log("countrys",res.data)
        });
    }
    loadSectors() {
        return this.$api.get(`sector`).then(res => {
            this.sectors = res.data;
            // console.log("countrys",res.data)
        });
    }
    loadIndices(countryId?: number | null) {
        // if (countryId) {
        //     return this.$api
        //         .get(`stock-exchange/?&filter[stockExchange.country_id]=${countryId}`)
        //         .then(res => {
        //             this.indices = res.data;
        //         });
        // }
        // } else {
        return this.$api.get(`stock-exchange`).then(res => {
            this.indices = res.data;
        });
        // }
    }
    onChangeSector(selectedItem: any) {
        // console.log("selected sextor", selectedItem);
        if (selectedItem) {
            // this.loadingData = true;
            this.sectorId = selectedItem;
        }
    }
    onChangeCountry(selectedItem: any) {
        // console.log("selected country", selectedItem);
        if (selectedItem) {
            // this.loadingData = true;
            this.countryFilterId = selectedItem;
        }
    }
    disableFiltersChange() {
        console.log("this.countryFilterId", this.countryFilterId)
        if (this.countryFilterId.length === 0 && this.sectorId.length === 0) {
            this.disableFilters = true
        } else {
            this.disableFilters = false
        }
    }
    setConforme(filter: string) {
        switch (filter) {
            case "score":
                this.scoreRange = [100, 200];
                break
            case "performance":
                this.performanceRange = [50, 100]
                break
            case "solidity":
                this.solidityRange = [50, 100]
                break
            case "liquidity":
                this.liquidityRange = [5, 10]
                break
            case "asset":
                this.assetRange = [5, 10]
                break
            case "liability":
                this.liabilityRange = [5, 10]
                break
            case "risk":
                this.riskRange = [5, 10]
                break
            case "commercial":
                this.commercialRange = [5, 10]
                break
            case "economic":
                this.economicRange = [5, 10]
                break
            case "financial":
                this.financialRange = [5, 10]
                break
            case "operating":
                this.operatingRange = [5, 10]
                break
        }
    }
    setNonConforme(filter: string) {
        switch (filter) {
            case "score":
                this.scoreRange = [0, 99];
                break
            case "performance":
                this.performanceRange = [0, 49]
                break
            case "solidity":
                this.solidityRange = [0, 49]
                break
            case "liquidity":
                this.liquidityRange = [0, 5]
                break
            case "asset":
                this.assetRange = [0, 5]
                break
            case "liability":
                this.liabilityRange = [0, 5]
                break
            case "risk":
                this.riskRange = [0, 5]
                break
            case "commercial":
                this.commercialRange = [0, 5]
                break
            case "economic":
                this.economicRange = [0, 5]
                break
            case "financial":
                this.financialRange = [0, 5]
                break
            case "operating":
                this.operatingRange = [0, 5]
                break
        }
    }
    loadComposition(selectedItem: any) {
        // console.log(selectedItem.id)
        this.error = null;
        // this.companyId = 0;
        // this.valo = '';
        // this.composition = {};
        // this.countryId = null;
        const lang = this.$i18n.locale;
        if (selectedItem) {
            // this.loadingData = true;
            const selectedCompany = this.companies.find(
                company => company.id === selectedItem.id
            );
            // console.log("selectedCompany:", selectedCompany)
            // console.log("selectedCompany: selectedItem", selectedItem)

            // console.log("Available companies:", this.companies);
            // console.log("selectedCompanyname:", selectedItem.country.name[lang])

            // this.countryId = selectedCompany.country.name[lang];
            // this.composition.company_id = selectedCompany.id;
            // this.composition.valo = 0;
            const company_currency = selectedItem.profil_fmp.currency;
            // console.log("company_currency", company_currency)
            if (selectedItem.origin == "FMP") {
                this.fmp = true;
            } else {
                this.fmp = false;
            }
            // console.log("this.composition", this.composition)
            // this.$api
            //     .get(
            //         `portfolio/company-info?id=${selectedItem.id}&devise=${company_currency}&portfolioDevise=${this.portfolioDevise}`
            //     )
            //     .then(res => {
            this.composition.push({
                company_id: selectedItem.id,
                name: selectedItem.name,
                // close: res.data.close,
                // score: res.data.score ? res.data.score.global.toFixed(3) : null,
                // solidite: res.data.score ? res.data.score.A1.toFixed(3) : null,
                // performance: res.data.score ? res.data.score.A2.toFixed(3) : null,
                country: selectedItem.country.name[lang],
                valo: 0,
                type: 1,
                company_currency: company_currency,
                fees: 0
            });
            // console.log("composition", this.composition)

            // this.composition.close = res.data.close;
            // if (res.data.score) {
            //     this.composition.score = res.data.score.global.toFixed(3);
            //     this.composition.solidite = res.data.score.A1.toFixed(3);
            //     this.composition.performance = res.data.score.A2.toFixed(3);
            // }

            //     this.loadingData = false;
            // })
            // .catch(error => {
            //     this.loadingData = false;
            //     console.error("Error loading company info:", error);
            // });
            // console.log("this.composition after", this.composition)

        }
    }
    loadClosing() {

    }
    addCompany(selectedCompany, index) {
        const formated = this.formatDateTime(this.composition[index].start_date)
        const date = new Date(formated);
        date.setHours(date.getHours() + 1);
        const formattedDate = date.toISOString().slice(0, 16).replace('T', ' ');

        this.composition[index].start_date = formattedDate
        this.$set(this.composition[index], 'loadingValidation', true);
        this.$set(this.composition[index], 'alertDismiss', true);
        const url = `portfolio/validate-portfolio-transaction?id=${this.portfolioId}`
        this.$api
            .post(url, {
                composition: selectedCompany,
                transaction: this.composition
            }).then(res => {
                // console.log("res", res)
                this.$set(this.composition[index], 'errorValidation', 0);
                this.$set(this.composition[index], 'errorMessage', "");
                this.$set(this.composition[index], 'loadingValidation', false);
                this.$set(this.composition[index], 'added', 1);

            }).catch(error => {
                this.selectedCompanies = this.selectedCompanies.filter(
                    company => company.id !== selectedCompany.company_id
                );
                // console.log("Error loading company check:", error.response);
                this.$set(this.composition[index], 'errorValidation', 1);
                this.$set(this.composition[index], 'errorMessage', error.response.data.message);
                this.$set(this.composition[index], 'loadingValidation', false);
                this.$set(this.composition[index], 'added', 0);
                setTimeout(() => {
                    this.$set(this.composition[index], 'alertDismiss', false);
                }, 4000)
                return

            });

        // console.log("this.companies ", this.companies)
        // const selectedCompany = this.companies.find(
        //     company => company.id === companyId
        // );
        // console.log("selectedCompanies ", this.selectedCompanies)

        // console.log("selectedCompnay add", selectedCompany)
        // Check if the company with the same ID already exists in selectedCompanies
        const exists = this.selectedCompanies.find(
            comp => Number(comp.id) === Number(selectedCompany.company_id)
        );
        // console.log("exists add", exists)

        this.doneUpload = false;

        // If the company doesn't exist in selectedCompanies, add it
        if (selectedCompany && !exists) {
            this.$set(this.composition[index], 'added', 0);
            this.soliditeMoy = 0;
            this.performanceMoy = 0;
            // upadte total's
            this.total = (
                parseFloat(this.composition[index].valo) + parseFloat(this.total)
            ).toFixed(3);
            // add new line
            // console.log("selectedCompany.  name", selectedCompany.name)

            this.selectedCompanies.push({
                id: selectedCompany.company_id,
                name: selectedCompany.name,
                symbol: selectedCompany.symbol,
                nbraction: this.composition[index].nbr_action,
                close: this.composition[index].close,
                valo: this.composition[index].valo,
                type: this.composition[index].type,
                global: this.composition[index].score,
                solidite: this.composition[index].solidite,
                performance: this.composition[index].performance,
                devise: this.composition[index].company_currency,
                fees: this.composition[index].fees,
                percentage: this.composition[index].percentage,
                editing: false,
                dateStart: this.composition[index].start_date,
            });

            this.selectedCompanies.map(item => {
                item.percentage = ((parseFloat(item.valo) / this.total) * 100).toFixed(
                    2
                );
            })
            //     this.soliditeMoy =
            //         parseFloat(this.soliditeMoy) +
            //         parseFloat(item.solidite * item.percentage) / 100;
            //     this.performanceMoy =
            //         parseFloat(this.performanceMoy) +
            //         parseFloat(item.performance * item.percentage) / 100;
            // });

            // this.scoreMoy =
            //     parseFloat(this.soliditeMoy) + parseFloat(this.performanceMoy);
        }
        // else{
        //     this.error = {msg : 'company exist deja'};
        // }

        // this.companyId = 0;
        // this.valo = "";
        // this.composition = {};
        // this.countryId = null;

        // console.log("jannt", this.selectedCompanies);
        // console.log("endddd", this.composition);
    }
    deleteCompany(id: any) {
        this.doneUpload = false;
        this.$root.$confirm
            .open(
                "Delete company",
                "Are you sure you want to delete this company ?",
                {}
            )
            .then(confirm => {
                if (confirm) {
                    this.selectedCompanies = this.selectedCompanies.filter(
                        company => company.id !== id
                    );
                    if (this.selectedCompanies.length > 0) {
                        this.calculate();
                    }
                }
            });
    }

    restrictInput(event, attributeName: string) {
        // Allow: backspace, delete, tab, escape, enter
        const allowedKeys = [
            'Backspace',
            'Delete',
            'Tab',
            'Escape',
            'Enter',

        ];
        const currentAttribute = this[attributeName];
        // Check for allowed keys
        if (allowedKeys.includes(event.key) || (event.ctrlKey && allowedKeys.includes(event.key))) {
            return; // Allow these keys
        }
        // Allow only digits and a decimal point
        const isDigit = event.key >= '0' && event.key <= '9';
        const isDecimalPoint = event.key === '.';

        // Prevent the first character from being anything other than a digit (not including decimal point)
        if (currentAttribute && currentAttribute.length === 0 && !isDigit && isDecimalPoint) {
            event.preventDefault(); // Block if the first character is not a digit
            return;
        }
        if (!currentAttribute && isDecimalPoint) {
            event.preventDefault(); // Block if the first character is not a digit
            return;
        }
        // Prevent multiple decimal points
        if (isDecimalPoint && currentAttribute.includes('.')) {
            event.preventDefault(); // Block if another decimal point is already present
            return;
        }

        // Block any invalid keys (anything other than digits and a decimal point)
        if (!isDigit && !isDecimalPoint) {
            event.preventDefault(); // Block invalid characters
        }
    }


    formatNumber(value: string) {
        return Number(value).toFixed(3);
    }
    editNbrAction(comp: any) {
        // Check if newAction is a valid number
        const parsedAction = parseFloat(comp.nbraction);
        if (isNaN(parsedAction) || parsedAction <= 0) {
            this.error = { msg: "invalid action" };
            return;
        }

        // Update the nbraction for the specific company
        this.selectedCompanies.forEach(item => {
            if (item.id === comp.id) {
                item.nbraction = parsedAction;
                item.editing = false; // Exit editing mode for this company
            }
        });

        // Recalculate any necessary values
        this.calculate();
    }
    editCompany(id: any) {
        this.selectedCompanies.forEach(item => {
            item.editing = item.id === id;
        });
    }
    async updateNbrAction(index) {
        this.$set(this.composition[index], 'added', 0);
        const item = this.composition[index]
        // console.log("item ", item)
        // console.log("item id", item["company_id"])
        this.selectedCompanies = this.selectedCompanies.filter(
            company => company.id !== item["company_id"]
        );
        let action = Number(this.composition[index].nbr_action);
        let close = Number(this.composition[index].close);
        let fees = Number(this.composition[index].fees);
        let transactionType = this.composition[index].type
        console.log("transactionType", transactionType)
        // if(this.composition.company_currency != this.portfolioDevise){
        //     const response =  await fetch('https://financialmodelingprep.com/api/v3/historical-chart/4hour/'+this.composition.company_currency+''+this.portfolioDevise+'?from='+this.yesterdayDate+'&to='+this.currentDate+'&apikey=016fd8297eb7a8be6bc3fdf9cc65240d');
        //     const data = await response.json();
        //     if(data.length > 0)
        //         convert = data[0].close;
        // }
        let valo = 0;
        if (transactionType === 1) {
            valo = (action * close) + fees
        } else {
            valo = (action * close) - fees
        }

        console.log("valo", valo)
        this.composition[index].valo = parseFloat(valo).toFixed(
            3
        );
    }
    async updateFees(index) {
        this.$set(this.composition[index], 'added', 0);
        const item = this.composition[index]
        // console.log("item ", item)
        // console.log("item id", item["company_id"])
        this.selectedCompanies = this.selectedCompanies.filter(
            company => company.id !== item["company_id"]
        );
        let action = Number(this.composition[index].nbr_action);
        let close = Number(this.composition[index].close);
        let fees = Number(this.composition[index].fees);

        let transactionType = this.composition[index].type
        console.log("transactionType", transactionType)
        // if(this.composition.company_currency != this.portfolioDevise){
        //     const response =  await fetch('https://financialmodelingprep.com/api/v3/historical-chart/4hour/'+this.composition.company_currency+''+this.portfolioDevise+'?from='+this.yesterdayDate+'&to='+this.currentDate+'&apikey=016fd8297eb7a8be6bc3fdf9cc65240d');
        //     const data = await response.json();
        //     if(data.length > 0)
        //         convert = data[0].close;
        // }
        let valo = 0;
        if (transactionType === 1) {
            valo = (action * close) + fees
        } else {
            valo = (action * close) - fees
        }
        this.composition[index].valo = parseFloat(valo).toFixed(
            3
        );
    }
    setActionType(index, type) {
        this.$set(this.composition[index], 'added', 0);
        this.composition[index].type = type; // Assuming 'companies' is the array holding your 'comp' objects
        console.log(this.composition)
    }
    uploadData() {
        // console.log(this.startVariation)
        // console.log("exist", this.exist)
        if (this.startVariation === null) {
            this.text = "date début variation est obligatoire !"
            this.snackbar = true
            return
        }

        this.uploadingData = true;
        this.update = false;
        // let url = `portfolio/integrate-version?portfolio=${this.portfolioId}`;
        const formattedStartVariation = this.formatDateTime(this.startVariation)
        // if (this.exist == false && this.startVariation)
        let url = `portfolio/integrate-version?id=${this.portfolioId}`;

        // console.log("eeeee", url, this.startVariation, this.exist);
        // console.log("startVariation", formattedStartVariation)
        // console.log("composition", this.selectedCompanies)

        this.$api
            .post(url, {
                composition: this.selectedCompanies,
                dateDebut: formattedStartVariation
            })
            .then(response => {
                // console.log("response", response)
                this.uploadingData = false;
                this.doneUpload = true;
                this.selectedCompanies = null
                window.location.reload();
            })
            .catch(e => {
                // console.log("e.response", e.response)
                this.error = e.response.data;
                this.uploadingData = false;
            })
            .finally(() => {
                this.doneUpload = true;
                window.location.reload();
            });
    }

    loadPortfolioInfo() {
        return this.$api
            .get(
                `portfolio/${this.portfolioId}?expand=portfolioVersions.portfolioComposition.company`
            )
            .then(res => {
                this.portfolio = res.data
                console.log("this.portfolio", this.portfolio)
                const dateStartPortfolio = res.data.start_date_variation;
                // console.log("datestart", dateStartPortfolio)

                if (dateStartPortfolio) {
                    this.openDialogVariationDate = false
                    const date = new Date(dateStartPortfolio);

                    // Add 1 hour (3600000 milliseconds)
                    date.setHours(date.getHours() + 1);

                    // Format the date to 'YYYY-MM-DD HH:MM'
                    const formattedDate = date.toISOString().slice(0, 16).replace('T', ' ');

                    this.startVariation = formattedDate;
                    console.log(formattedDate)
                } else {
                    this.openDialogVariationDate = true
                }
                if (
                    res.data.portfolioVersions &&
                    res.data.portfolioVersions.length > 0
                ) {
                    const lastPortfolioVersion = res.data.portfolioVersions
                        .slice()
                        .sort((a, b) => new Date(a.date_version) - new Date(b.date_version))
                        .pop();

                    this.lastVersion = lastPortfolioVersion;
                    // console.log("this.lastVersion", this.lastVersion)


                    // console.log(" this.startVariation", this.startVariation)
                    // console.log(" this.startVariation", typeof (this.startVariation))

                    // Map the portfolioComposition of the last version to selectedCompanies
                    // this.selectedCompanies = lastPortfolioVersion.portfolioComposition.map(
                    //     item => ({
                    //         id: item.company_id,
                    //         name: item.company.name,
                    //         symbol: item.company.symbol,
                    //         nbraction: item.nbr_action,
                    //         close: parseFloat(item.close).toFixed(3),
                    //         valo: parseFloat(item.valo).toFixed(3),
                    //         global: parseFloat(item.score).toFixed(3),
                    //         solidite: parseFloat(item.solidite).toFixed(3),
                    //         performance: parseFloat(item.performance).toFixed(3),
                    //         devise: item.company_currency,
                    //         dateStart: item.date_variation,
                    //         percentage: parseFloat(item.percentage).toFixed(2),
                    //         editing: false,
                    //     })
                    // );
                    console.log("lastversion", this.lastVersion)
                    // this.lastVersion.portfolioComposition.map(itemx => {
                    //     this.total = parseFloat(itemx.valo) + parseFloat(this.total);
                    //     this.soliditeMoy =
                    //         parseFloat(this.soliditeMoy) +
                    //         parseFloat(itemx.solidite * itemx.percentage) / 100;
                    //     this.performanceMoy =
                    //         parseFloat(this.performanceMoy) +
                    //         parseFloat(itemx.performance * itemx.percentage) / 100;
                    // });

                    this.scoreMoy =
                        parseFloat(this.soliditeMoy) + parseFloat(this.performanceMoy);
                    this.exist = true;
                } else {
                    // Handle case where portfolioVersions is empty
                    this.selectedCompanies = [];
                    this.exist = false;


                }
            });
    }

    calculate() {
        this.total = 0;
        this.soliditeMoy = 0;
        this.performanceMoy = 0;
        this.selectedCompanies.map(item => {
            item.valo = (parseFloat(item.close) * parseFloat(item.nbraction)).toFixed(
                3
            );
            this.total = parseFloat(item.valo) + parseFloat(this.total);
        });
        this.selectedCompanies.map(itemx => {
            itemx.percentage = ((parseFloat(itemx.valo) / this.total) * 100).toFixed(
                2
            );
            this.soliditeMoy =
                parseFloat(this.soliditeMoy) +
                parseFloat(itemx.solidite * itemx.percentage) / 100;
            this.performanceMoy =
                parseFloat(this.performanceMoy) +
                parseFloat(itemx.performance * itemx.percentage) / 100;
        });

        this.scoreMoy =
            parseFloat(this.soliditeMoy) + parseFloat(this.performanceMoy);
        this.error = null;
    }

    onChangePicker(comp, index) {
        console.log(comp)
        this.$set(this.composition[index], 'added', 0);
        const start_date = new Date(comp.start_date);
        const formattedDate = [
            start_date.getFullYear(),
            String(start_date.getMonth() + 1).padStart(2, "0"),
            String(start_date.getDate()).padStart(2, "0"),
        ].join("-");
        this.$api
            .get(
                `portfolio/company-info?id=${comp.company_id}&devise=${comp.company_currency}&portfolioDevise=${this.portfolioDevise}&date=${formattedDate}`
            )
            .then(res => {
                comp.close = res.data.close
                comp.score = res.data.score ? res.data.score.global.toFixed(3) : null
                comp.solidite = res.data.score ? res.data.score.A1.toFixed(3) : null
                comp.performance = res.data.score ? res.data.score.A2.toFixed(3) : null
                this.composition = [...this.composition];
            })
    }
    formatDate(date: any) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }
    formatDateTime(dateString: any) {
        const date = new Date(dateString); // Parse the input date string

        // Extract date parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Return in the desired format
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    viewCompany(id) {
        const routePath = `/company/${id}`;
        // Use window.open to open the route in a new tab
        window.open(routePath, "_blank");
        //this.$router.push(`/company/${id}`);
    }

    viewIndex() {
        const routePath = `/portfolio/${this.portfolioId}`;
        window.open(routePath, "_blank");
    }

    isDisabled() {
        // console.log("exist", this.exist)
        return this.exist;
    }
}
